import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { bindActionCreators } from "redux";

import { Trackable, trackProps } from "@dpdgroupuk/react-event-tracker";

import { DASHBOARD_SEARCH_TO_FILTER_MAP } from "../../constants/forms";
import DeliveryListView, {
  withDeliveryAnalytics,
  withDeliveryHandlers,
} from "../../features/DeliveryListView";
import { DeliveriesActions } from "../../redux/deliveries";
import { getCustomers } from "../../redux/ums/selectors";
import { getTitle, trackLoadAction } from "./utils";

const TrackableDeliveryPage = ({ loadId, interfaceId, ...rest }) => {
  const pageTitle = getTitle(rest.match.params.deliveryType);

  return (
    <Trackable loadId={loadId} interfaceId={interfaceId}>
      <DeliveryListView {...rest} pageTitle={pageTitle} />
    </Trackable>
  );
};

TrackableDeliveryPage.propTypes = {
  loadId: PropTypes.string,
  interfaceId: PropTypes.string,
  watchListItems: PropTypes.array,
};

const mapStateToProps = state => ({
  customers: getCustomers(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  ...bindActionCreators(DeliveriesActions, dispatch),
  findParcels: (query, fetchOptions) =>
    dispatch(
      DeliveriesActions.searchParcelsByType(
        query,
        match.params.deliveryType,
        fetchOptions
      )
    ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ match }) => ({
    ...trackLoadAction(match.params.deliveryType, trackProps),
    searchFilterMap: DASHBOARD_SEARCH_TO_FILTER_MAP,
    getParcelsExportPageTile: () => getTitle(match.params.deliveryType),
  })),
  withDeliveryHandlers,
  withDeliveryAnalytics
)(TrackableDeliveryPage);
