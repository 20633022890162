import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import { combineAppReducer } from "@dpdgroupuk/mydpd-app";
import { combineAppReducer as chatReducers } from "@dpdgroupuk/mydpd-chat";

import { FilterFields } from "../constants/forms";
import { uiReducers } from "../pages";
import { DeliveriesReducer } from "./deliveries";
import { ExporterParcelsReducer } from "./exporterParcels";
import { fieldValueToUppercase, toUppercase } from "./reduxForm/plugins";
import { ReferenceReducer } from "./references";
import { UmsReducer } from "./ums";

const combinePlugins = plugins => (state, action) =>
  plugins.reduce((acc, curr) => curr(acc, action), state);

export default combineReducers({
  form: form.plugin({
    SERVICE_DISRUPTION_FORM: combinePlugins([toUppercase]),
    DELIVERIES_SEARCH_FILTER_FORM: combinePlugins([
      fieldValueToUppercase(FilterFields.POSTCODE),
    ]),
    SEND_EMAIL_FORM: combinePlugins([toUppercase]),
    PARCEL_EDIT_FORM: combinePlugins([toUppercase]),
    WATCH_LIST_FORM: combinePlugins([toUppercase]), // TODO: check it
  }),
  deliveries: DeliveriesReducer,
  exporterParcels: ExporterParcelsReducer,
  app: combineAppReducer({
    ums: UmsReducer,
  }),
  chat: chatReducers({}),
  references: ReferenceReducer,
  ui: uiReducers,
});
