import { ActionTypes } from "./actions";

export const initialState = {
  exporterParcels: [],
  totalExporterParcels: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FIND_EXPORTER_PARCEL.SUCCESS: {
      const { results, totalResults } = action.payload.data;

      return {
        ...state,
        exporterParcels: results,
        totalExporterParcels: totalResults,
      };
    }

    case ActionTypes.GET_EXPORTER_PARCEL.SUCCESS: {
      return {
        ...state,
        exporterParcels: state.exporterParcels.map(item => {
          if (item.exportParcelId === action.payload.exportParcelId) {
            return action.payload;
          }

          return item;
        }),
      };
    }

    default:
      return state;
  }
};
