import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { PICKUP_LOCATION_STATUS } from "@dpdgroupuk/redback-enums";

import { GENERAL_VIEW } from "../../../constants/analytics";
import {
  PARCEL_IS_READY_TO_COLLECT_FROM_PICKUP,
  PARCEL_TO_BE_COLLECTED_FROM_PICKUP_SHOP,
} from "../../../constants/strings";
import { ParcelTypes } from "../../../models/types";
import { fetchPickupLocation } from "../actions";
import AdditionalInformation from "../components/AdditionalInformation";
import HeaderWithDetails from "../components/HeaderWithDetails";
import ParcelHistory from "../components/ParcelHistory/ParcelHistory";
import PickupDetails from "../components/PickupDetails";
import RelatedParcels from "../components/RelatedParcels";
import { getPickupLocation } from "../selectors";
import styles from "./PickupDeliveryParcelView.module.scss";

const PickupDeliveryParcelView = ({
  parcel,
  children,
  pickupLocation,
  isRelatedParcelsAvailable,
  onClickDeliveryHistory,
  onClickConsignment,
  onClickAdditionalInformation,
  onClickShowMap,
}) => {
  const { pickupStatus } = parcel;
  const status = parseInt(pickupStatus);

  return (
    <>
      <HeaderWithDetails
        parcel={parcel}
        additionalParcelStatus={
          <div className={styles.title}>
            {status === PICKUP_LOCATION_STATUS.INCOMPLETE
              ? PARCEL_TO_BE_COLLECTED_FROM_PICKUP_SHOP
              : PARCEL_IS_READY_TO_COLLECT_FROM_PICKUP}
          </div>
        }
      >
        <PickupDetails
          parcel={parcel}
          pickupLocation={pickupLocation}
          onClickShowMap={onClickShowMap}
        />
      </HeaderWithDetails>
      {children}
      <ParcelHistory
        data={parcel.parcelEvents}
        onOpen={onClickDeliveryHistory}
      />
      {isRelatedParcelsAvailable && (
        <RelatedParcels
          parcelCode={parcel.parcelCode}
          onOpen={onClickConsignment}
        />
      )}
      <AdditionalInformation
        parcel={parcel}
        onOpen={onClickAdditionalInformation}
      />
    </>
  );
};

PickupDeliveryParcelView.propTypes = {
  parcel: PropTypes.shape(ParcelTypes.Parcel),
  children: PropTypes.node,
  pickupLocation: PropTypes.object,
  onClickDeliveryHistory: PropTypes.func,
  onClickConsignment: PropTypes.func,
  onClickAdditionalInformation: PropTypes.func,
  onClickShowMap: PropTypes.func,
};

export default compose(
  withAppLoader({
    loadFn: ({ dispatch, parcel }, fetchOptions) =>
      dispatch(fetchPickupLocation(parcel.pickupLocationCode, fetchOptions)),
  }),
  connect(state => ({
    pickupLocation: getPickupLocation(state),
  })),
  withTrackProps({
    onClickShowMap: GENERAL_VIEW.CLICK_SHOW_SHOP_MAP_PICKUP_DELIVERY,
  })
)(PickupDeliveryParcelView);
