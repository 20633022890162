import { Col } from "react-bootstrap";

import CardWithTitle from "../../../../components/CardWithTitle";
import RelatedParcelsList from "./RelatedParcelsList";

const RelatedParcels = rest => (
  <Col sm={12}>
    <CardWithTitle title="Consignment">
      <RelatedParcelsList {...rest} />
    </CardWithTitle>
  </Col>
);

export default RelatedParcels;
