import {
  createActionTypes,
  createAsyncActionTypes,
  createAsyncAction,
} from "@dpdgroupuk/redux-action-creator";

import { exporterParcelsApi } from "../../apis";

export const ActionTypes = createActionTypes("EXPORTER_PARCEL", {
  FETCH: createAsyncActionTypes("FETCH"),
  SEARCH: createAsyncActionTypes("SEARCH"),
  FIND_EXPORTER_PARCEL: createAsyncActionTypes("FIND_EXPORTER_PARCEL"),
  FIND_EXPORTER_PARCEL_FILE_URL: createAsyncActionTypes(
    "FIND_EXPORTER_PARCEL_FILE_URL"
  ),
  GET_EXPORTER_PARCEL: createAsyncActionTypes("GET_EXPORTER_PARCEL"),
});

export const findExporterParcels = createAsyncAction(
  ({ page, pageSize }, options) => {
    return exporterParcelsApi.getExporterParcels(
      {
        searchPage: page,
        searchPageSize: pageSize,
      },
      options
    );
  },
  ActionTypes.FIND_EXPORTER_PARCEL
);

export const getExporterParcelsUrl = createAsyncAction(
  async (exportParcelId, options) => {
    const {
      data: { fileUrl },
    } = await exporterParcelsApi.getExporterParcelsUrl(
      { exportParcelId },
      options
    );

    return fileUrl;
  },
  ActionTypes.FIND_EXPORTER_PARCEL_FILE_URL
);

export const refreshExporterParcel = createAsyncAction(
  async (exporterParcelId, options) => {
    const { data } = await exporterParcelsApi.getExporterParcel(
      exporterParcelId,
      options
    );

    return data;
  },
  ActionTypes.GET_EXPORTER_PARCEL
);
