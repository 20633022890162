import { Fields } from "../constants/forms";
import * as M from "../constants/strings";

const FieldsByName = Object.keys(Fields).reduce((acc, key) => {
  acc[Fields[key]] = key;
  return acc;
}, {});

const transformReadableQuerySearchParams = querySearchParams => {
  try {
    const params = JSON.parse(querySearchParams);

    return Object.keys(params)
      .filter(el => el !== "findByCode")
      .map(key => `${M[FieldsByName[key]] || key}: "${params[key]}"`)
      .join("; ");
  } catch {
    return querySearchParams;
  }
};

export const prepareExporterParcelsView = (exporterParcels = []) =>
  exporterParcels.map(exporterParcel => ({
    ...exporterParcel,
    querySearchParams: transformReadableQuerySearchParams(
      exporterParcel.querySearchParams
    ),
  }));
