import { connect } from "react-redux";
import { compose, withProps } from "recompose";

import { withTrack } from "@dpdgroupuk/react-event-tracker";

import { SEARCH_RESULT_LIST } from "../../constants/analytics";
import { DELIVERY_SEARCH } from "../../constants/strings";
import { SEARCH_TO_FILTER_MAP } from "../../constants/forms";
import DeliveryListView, {
  withDeliveryAnalytics,
  withDeliveryHandlers,
} from "../../features/DeliveryListView";
import { DeliveriesActions } from "../../redux/deliveries";

export default compose(
  withTrack(SEARCH_RESULT_LIST),
  connect(null, DeliveriesActions),
  withProps({
    searchFilterMap: SEARCH_TO_FILTER_MAP,
    getParcelsExportPageTile: () => DELIVERY_SEARCH,
  }),
  withDeliveryHandlers,
  withDeliveryAnalytics
)(DeliveryListView);
