import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, lifecycle } from "recompose";
import { Paginator, Card, Main, withOverlay } from "@dpdgroupuk/mydpd-ui";
import { Col } from "react-bootstrap";
import { withAppLoader, withNotifier } from "@dpdgroupuk/mydpd-app";

import withReportFeedbackHandler from "../../HOCS/withReportFeedbackHandler";
import withOnLoadFailure from "../../HOCS/withOnLoadFailure";
import ExportsList from "./components/ExportsList";
import {
  getQueryPagination,
  getLocationState,
  stringifyQuery,
} from "../../utils/query";
import {
  getTotalExporterParcelsCount,
  getTotalExporterParcels,
} from "../../redux/exporterParcels/selectors";
import { ExporterParcelsActions } from "../../redux/exporterParcels";
import { prepareExporterParcelsView } from "../../models/exporterParcel";

const ExporterParcels = ({
  page,
  totalCount,
  pageSize,
  exporterParcels,
  onClickRow,
  onClickDownload,
  onClickRefresh,
  onNext,
  onPrevious,
  onFirst,
  onLast,
  onUpdateParcel,
}) => {
  return (
    <Main body>
      <Card.Stack>
        <Col className="col-12"></Col>
        <Col className="col-12">
          <ExportsList
            exporterParcels={exporterParcels}
            page={page}
            totalCount={totalCount}
            pageSize={pageSize}
            onClickRow={onClickRow}
            onNext={onNext}
            onPrevious={onPrevious}
            onFirst={onFirst}
            onLast={onLast}
            onUpdateParcel={onUpdateParcel}
            onClickDownload={onClickDownload}
            onClickRefresh={onClickRefresh}
          />
        </Col>
      </Card.Stack>
    </Main>
  );
};
ExporterParcels.defaultProps = {
  onClickRow: () => null,
  onUpdateParcel: () => null,
  exporterParcels: [],
};

export const propTypes = {
  ...Paginator.propTypes,
};

ExporterParcels.propTypes = propTypes;

export default compose(
  withRouter,
  withReportFeedbackHandler,
  withOnLoadFailure,
  withOverlay,
  withNotifier,
  connect(
    (state, { location }) => {
      const { page, pageSize } = getQueryPagination(location);

      return {
        page,
        pageSize,
        totalCount: getTotalExporterParcelsCount(state),
        exporterParcels: prepareExporterParcelsView(
          getTotalExporterParcels(state)
        ),
      };
    },
    (dispatch, { history, location, overlay, notifier }) => {
      const findExporterParcels = (query, options) =>
        dispatch(ExporterParcelsActions.findExporterParcels(query, options));

      const onNextOrPrev = async targetPage => {
        const paginationParams = getQueryPagination(location);

        history.push({
          search: stringifyQuery({
            ...paginationParams,
            page: targetPage,
          }),
          state: getLocationState(location),
        });
      };

      return {
        onNext: onNextOrPrev,
        onPrevious: onNextOrPrev,
        onFirst: onNextOrPrev,
        onLast: onNextOrPrev,
        findExporterParcels,
        onClickDownload: notifier.runAsync(async exporterParcel => {
          try {
            overlay.show();

            const fileUrl = await dispatch(
              ExporterParcelsActions.getExporterParcelsUrl(
                exporterParcel.exportParcelId
              )
            );

            if (fileUrl) {
              const alink = document.createElement("a");
              alink.href = fileUrl;
              alink.click();
            }
          } finally {
            overlay.hide();
          }
        }),
        onClickRefresh: notifier.runAsync(async exporterParcel => {
          try {
            overlay.show();

            try {
              overlay.show();
              await dispatch(
                ExporterParcelsActions.refreshExporterParcel(
                  exporterParcel.exportParcelId
                )
              );
            } finally {
              overlay.hide();
            }
          } finally {
            overlay.hide();
          }
        }),
      };
    }
  ),
  withAppLoader({
    loadFn: async ({ location, findExporterParcels }, fetchOptions) => {
      const pageParams = getQueryPagination(location);

      await findExporterParcels(pageParams, fetchOptions);
    },
  }),
  lifecycle({
    async componentDidUpdate(prevProps) {
      if (prevProps.location !== this.props.location) {
        const pageParams = getQueryPagination(this.props.location);

        await prevProps.findExporterParcels(pageParams);
      }
    },
  })
)(ExporterParcels);
