import get from "lodash/get";
import { createSelector } from "reselect";

import { sortByDate } from "./model";

export const getParcelDetails = state => state.ui.parcelView;

export const getParcel = createSelector(getParcelDetails, ({ parcel }) => ({
  ...parcel,
  parcelEvents: sortByDate(parcel.parcelEvents),
}));

export const getParcelActions = createSelector(
  getParcelDetails,
  ({ actions }) => actions
);
export const getRelatedParcels = createSelector(
  getParcelDetails,
  ({ relatedParcels }) => relatedParcels.parcels
);

export const getRelatedParcelsTotal = createSelector(
  getParcelDetails,
  data => data.relatedParcels.findByTotal
);

export const getParcelDeliveryDetails = createSelector(
  getParcelDetails,
  ({ parcel }) => get(parcel, "deliveryDetails", {})
);

export const getParcelAddress = createSelector(
  getParcelDeliveryDetails,
  ({ address }) => address
);

export const getCountry = createSelector(
  getParcelAddress,
  ({ countryName }) => countryName
);

export const getRoute = createSelector(getParcelDetails, ({ route }) => route);

export const getDriver = createSelector(
  getParcelDetails,
  ({ driver }) => driver
);

export const getParcelCode = createSelector(
  getParcel,
  ({ parcelCode }) => parcelCode
);

export const getDepot = createSelector(getParcelDetails, ({ depot }) => depot);

export const getPickupLocation = createSelector(
  getParcelDetails,
  ({ pickupLocation }) => pickupLocation
);

export const getAddressPoint = createSelector(
  getParcelAddress,
  ({ addressPoint }) => addressPoint
);

export const isActionsAvailable = createSelector(getParcelActions, actions => {
  const availableActions = get(actions, "availableActions", []);
  return !!availableActions.length;
});

export const isRelatedParcelsAvailable = createSelector(
  getRelatedParcelsTotal,
  totalParcels => !!totalParcels
);
