import { useCallback, useMemo } from "react";

import { get, last } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";
import { trackProps, withTrack } from "@dpdgroupuk/react-event-tracker";

import Delimiter from "../../../components/Delimiter";
import { OUT_FOR_PARCEL } from "../../../constants/analytics";
import { ParcelTypes } from "../../../models/types";
import { fetchDriver, fetchRoute } from "../actions";
import AdditionalInformation from "../components/AdditionalInformation";
import EtaBar from "../components/EtaBar";
import HeaderWithDetails from "../components/HeaderWithDetails";
import MapView from "../components/MapView";
import ParcelHistory from "../components/ParcelHistory";
import RelatedParcels from "../components/RelatedParcels";
import { getMapUrl, getParcelProgressData } from "../model";
import { getDriver, getRoute } from "../selectors";

const OutForDeliveryParcelView = ({
  parcel,
  driver,
  route,
  children,
  isRelatedParcelsAvailable,
  onClickDeliveryHistory,
  onClickConsignment,
  onClickAdditionalInformation,
}) => {
  const {
    outForDeliveryDetails: { mapAvailable },
    parcelCode,
    parcelEvents,
    deliveryDetails,
  } = parcel;

  const result = useMemo(
    () => getParcelProgressData(parcel, route, driver),
    // eslint-disable-next-line
    [parcel]
  );

  const Map = useCallback(
    () => (mapAvailable ? <MapView src={getMapUrl(parcelCode) || ""} /> : null),
    // eslint-disable-next-line
    [mapAvailable, deliveryDetails]
  );

  return (
    <>
      <HeaderWithDetails parcel={parcel}>
        <Delimiter />
        <EtaBar {...result} />
        <Map />
        <Delimiter />
      </HeaderWithDetails>
      {children}
      <Delimiter />
      <ParcelHistory data={parcelEvents} onOpen={onClickDeliveryHistory} />
      {isRelatedParcelsAvailable && (
        <RelatedParcels
          parcelCode={parcel.parcelCode}
          onOpen={onClickConsignment}
        />
      )}
      <AdditionalInformation
        parcel={parcel}
        onOpen={onClickAdditionalInformation}
      />
    </>
  );
};

OutForDeliveryParcelView.propTypes = {
  parcel: PropTypes.shape(ParcelTypes.Parcel),
  children: PropTypes.node,
  driver: PropTypes.object,
  route: PropTypes.object,
  innerComponent: PropTypes.node,
  onClickDeliveryHistory: PropTypes.func,
  onClickConsignment: PropTypes.func,
  onClickAdditionalInformation: PropTypes.func,
};

export default compose(
  withAppLoader({
    loadFn: async ({ dispatch, parcel }) => {
      const { parcelEvents, deliveryDepot = {} } = parcel;
      const parcelEvent = last(parcelEvents);
      const routeTime = get(parcelEvent, "routeTime");
      const routeNumber = get(parcelEvent, "routeNo");
      const depotCode = get(deliveryDepot, "depotCode");
      const routeCode = get(deliveryDepot.route, "routeCode");

      const route = await dispatch(
        fetchRoute({
          ...(routeCode && { routeCode }),
          ...(routeTime && { routeTime }),
          ...(routeNumber && { routeNumber }),
          ...(depotCode && { depotCode }),
        })
      );
      const driverCode = get(route.data, "driverCode");
      await dispatch(fetchDriver(`${depotCode}*${driverCode}`));
    },
  }),
  connect(state => ({
    route: getRoute(state),
    driver: getDriver(state),
  })),
  withTrack(trackProps(OUT_FOR_PARCEL))
)(OutForDeliveryParcelView);
