import { useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { useFlexLayout } from "react-table";
import moment from "moment-timezone";
import {
  Paginator,
  DndTable as Table,
  DndPaginator as TablePaginator,
} from "@dpdgroupuk/mydpd-ui";

import CardWithTitle from "../../../../components/CardWithTitle";
import { DEFAULT_DATE_TIME_FORMAT } from "../../../../constants/dateFormats";
import * as M from "../../../../constants/strings";
import styles from "./ExportsList.module.scss";
import StatusCell from "../StatusCell";

export const getColumns = ({ onClickDownload, onClickRefresh }) => [
  {
    Header: "Query Params",
    accessor: "querySearchParams",
  },
  {
    Header: "File name",
    accessor: "fileName",
  },
  {
    Header: "Created at",
    Cell: ({
      row: {
        original: { createdAt },
      },
    }) => moment(createdAt).format(DEFAULT_DATE_TIME_FORMAT),
    width: 80,
  },
  {
    Header: "Last Updated",
    Cell: ({
      row: {
        original: { lastUpdated },
      },
    }) => moment(lastUpdated).format(DEFAULT_DATE_TIME_FORMAT),
    width: 80,
  },
  {
    Header: "Status",
    Cell: ({ row: { original } }) => (
      <StatusCell
        status={original.status}
        onClickDownload={() => onClickDownload(original)}
        onClickRefresh={() => onClickRefresh(original)}
      />
    ),
    width: 80,
  },
];

const ExportsList = ({
  exporterParcels,
  page,
  totalCount,
  pageSize,
  onPrevious,
  onNext,
  onFirst,
  onLast,
  onClickRow,
  onClickDownload,
  onClickRefresh,
}) => {
  const memoColumns = useMemo(
    () => getColumns({ onClickDownload, onClickRefresh }),
    [onClickDownload, onClickRefresh]
  );
  const tableRef = useRef(null);

  return (
    <CardWithTitle title={M.EXPORTS}>
      <div className={styles.table}>
        <Table
          tableRef={tableRef}
          data={exporterParcels}
          columns={memoColumns}
          onClickRow={onClickRow}
          tableHooks={[useFlexLayout]}
          classes={{ container: styles.tableContent }}
        >
          <TablePaginator
            tableRef={tableRef}
            page={page}
            pageSize={pageSize}
            totalCount={totalCount}
            onPrevious={onPrevious}
            onNext={onNext}
            onFirst={onFirst}
            onLast={onLast}
          />
        </Table>
      </div>
    </CardWithTitle>
  );
};

export const propTypes = {
  exporterParcels: PropTypes.arrayOf(PropTypes.object),
  onClickDownload: PropTypes.func,
  onClickRefresh: PropTypes.func,
  ...Paginator.propTypes,
};
ExportsList.defaultProps = {
  onClickRow: () => null,
  page: 0,
  pageSize: 0,
  totalCount: 0,
  exporterParcels: [],
};
ExportsList.propTypes = propTypes;

export default ExportsList;
