import { PARCEL_DASHBOARD_TYPES } from "@dpdgroupuk/redback-enums";

import { DASHBOARD_RESULT_VIEW } from "../../constants/analytics";
import {
  DATA_ONLY_NO_PARCEL,
  EXCEPTIONS,
  INSTRUCTIONS_PROGRESS,
  INSTRUCTIONS_REQUIRED,
  OVERDUE,
  PARCELS_NO_DATA,
  PRO_ALERT,
  RETURNS,
  WATCH_LIST,
} from "../../constants/strings";

export const trackLoadAction = (deliveryType, handler) => {
  switch (deliveryType) {
    case PARCEL_DASHBOARD_TYPES.OVERDUE:
    case PARCEL_DASHBOARD_TYPES.RFI_WATCH:
    case PARCEL_DASHBOARD_TYPES.RFI_INSTRUCTIONS_REQUIRED:
    case PARCEL_DASHBOARD_TYPES.RFI_INSTRUCTIONS_PROVIDED:
    case PARCEL_DASHBOARD_TYPES.RFI_MISSING_DATA:
    case PARCEL_DASHBOARD_TYPES.PRE_ADVICE_ONLY:
    case PARCEL_DASHBOARD_TYPES.RFI_EXCEPTION:
    case PARCEL_DASHBOARD_TYPES.PRO_ALERT:
    case PARCEL_DASHBOARD_TYPES.RFI_RETURN:
      return handler(DASHBOARD_RESULT_VIEW);
    default:
      break;
  }
};

const assocDeliveryTypeTitle = {
  [PARCEL_DASHBOARD_TYPES.OVERDUE]: OVERDUE,
  [PARCEL_DASHBOARD_TYPES.RFI_WATCH]: WATCH_LIST,
  [PARCEL_DASHBOARD_TYPES.RFI_INSTRUCTIONS_REQUIRED]: INSTRUCTIONS_REQUIRED,
  [PARCEL_DASHBOARD_TYPES.RFI_INSTRUCTIONS_PROVIDED]: INSTRUCTIONS_PROGRESS,
  [PARCEL_DASHBOARD_TYPES.RFI_MISSING_DATA]: PARCELS_NO_DATA,
  [PARCEL_DASHBOARD_TYPES.PRE_ADVICE_ONLY]: DATA_ONLY_NO_PARCEL,
  [PARCEL_DASHBOARD_TYPES.RFI_EXCEPTION]: EXCEPTIONS,
  [PARCEL_DASHBOARD_TYPES.PRO_ALERT]: PRO_ALERT,
  [PARCEL_DASHBOARD_TYPES.RFI_RETURN]: RETURNS,
};

export const getTitle = deliveryType => assocDeliveryTypeTitle[deliveryType];
