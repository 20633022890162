import { formatMessage } from "../utils/string";

// Collections menu
export const SEARCH = "Search";
export const FILTER = "Filter";
export const RESET = "Reset";
export const WATCH_LIST = "Watch List";
export const DASHBOARD = "Dashboard";
export const EXPORTS = "Exports";
export const UPPERCASE = "uppercase";

// Titles
export const INSTRUCTIONS_REQUIRED = "Instructions Required";
export const INSTRUCTIONS_PROGRESS = "Instructions in Progress";
export const OVERDUE = "Overdue";
export const PARCELS_NO_DATA = "Parcel Only No Data";
export const DATA_ONLY_NO_PARCEL = "Data Only No Parcel";
export const EXCEPTIONS = "Exceptions";
export const PRO_ALERT = "ProAlert";
export const RETURNS = "Returns";
export const DELIVERY_DASHBOARD = "Delivery Dashboard";
export const DELIVERY_SEARCH = "Delivery Search";
export const DELIVER_NEIGHBOUR = "Select a neighbour";
export const CHANGE_ADDRESS = "Change Address";
export const CHANGE_ADDRESS_UPGRADE = "Change Address & Upgrade";
export const PROVIDE_INSTRUCTIONS = "Provide Delivery Instructions";
export const CHANGE_DATE = "Change Delivery Date";
export const COLLECT_DEPOT = "Collect From Depot";
export const DELIVER_PARCEL_TO_NEIGHBOUR = "Deliver The Parcel To A Neighbour";
export const DELIVER_SAFE_PLACE = "Deliver To Safe Place";
export const RETURN_TO_SENDER = "Return To Sender";
export const UPDATE_DETAILS = "Update Contact Details";
export const DISPOSE_REQUEST_TITLE = "Dispose Request";
export const SERVICE_DISRUPTION = "Service Disruption";
export const NO_SERVICE_DISRUPTION =
  "There are currently no delays to service throughout our network";
export const DELAY = "Delay in days from today";
export const POSTCODES = "Postcodes";
export const REDELIVER = "Redeliver To Same Address";
export const UPGRADE_DELIVERY = "Upgrade Delivery";
export const FOR_YOUR_ATTENTION = "For Your Attention";
export const AVAILABLE_OPTIONS = "Available Options";
export const WATCH_LIST_ADD = "Add To Watch List";
export const WATCH_LIST_REMOVE = "Remove From Watch List";
export const CANCEL = "Cancel";
export const ACCESS_DENIED = "Access Denied";
export const CALL_ME = "Call Me";
export const NO_WATCH_LIST_RESULTS = "No watch list results found";

// Labels
export const ACCOUNT = "Account";
export const ALL_ACCOUNTS = "All Accounts";
export const ALL_COUNTRIES = "All Countries";
export const CASE_CODE = "Case Reference";
export const CUSTOMER_REFERENCE = "Customer Reference";
export const COLLECTION_CODE = "Collection No";
export const COLLECTION_COUNTRY = "Collection Country";
export const SORT_BY = "Sort By";
export const DATE = "Date";
export const DATE_TYPE = "Date Type";
export const FROM_DATE = "From Date";
export const TO_DATE = "To Date";
export const EMAIL_ADDRESS = "Email Address";
export const EMAIL = "Email";
export const SUBJECT = "Subject";
export const MESSAGE = "Message";
export const PHONE_NUMBER = "Phone Number";
export const POSTCODE = "Postcode";
export const PLEASE_CHOOSE_A_FROM_DATE = "Please choose a From date";
export const PLEASE_CHOOSE_A_TO_DATE = "Please choose a To date";
export const TOWN = "Town";
export const COUNTY = "County";
export const CONTACT_DETAILS = "Contact details";
export const NOT_SPECIFIED = "Not Specified";
export const NOT_SPECIFIED_VALUE = "not-specified";
export const CONTACT_NAME = "Contact Name";
export const NOTIFICATION_MOBILE = "Notification Mobile";
export const NOTIFICATION_EMAIL = "Notification Email";
export const DELIVERY_REFERENCE = "Parcel Reference";
export const PRODUCT = "Product";
export const ALL_PRODUCTS = "All Products";
export const SERVICE = "Service";
export const ALL_SERVICES = "All Services";
export const DELIVERY_DEPOT = "Depot";
export const ALL_DEPOTS = "All Depots";
export const DESTINATION = "Destination";
export const ALL_DESTINATIONS = "All destinations";
export const RANGE = "Range";
export const ALL_RANGES = "All Ranges";
export const OFD_TODAY = "OFD Today";
export const DELIVERED = "Delivered";
export const DELIVERY_COUNTRY = "Delivery Country";
export const DIRECTION = "Direction";
export const ALL_DIRECTIONS = "All Directions";
export const OVERDUE_DAYS = "All Days";
export const EXCEPTION = "Exceptions";
export const ALL_EXCEPTIONS = "All Exceptions";
export const SELECT_DATE = "Select Date";
export const PLEASE_SELECT_SERVICE = "Please select a Service";
export const UTILITY_BILL = "Utility Bill";
export const PHOTO_ID = "Photo ID (Driving licence or Passport)";
export const DPD_PICKUP_PASS = "DPD Pickup Pass";
export const COUNTRY = "Country";
export const NAME = "Name";
export const CITY = "City";
export const REFERENCE = "Reference";
export const SELECT_ADDRESS = "Select Address";
export const PLEASE_SELECT_DATE = "Please select date";
export const DELIVERY_SERVICE = "Delivery Service";
export const RESULTS = "Results";
export const INSTRUCTION_AND_CONTACT_DETAILS = "Instructions & Contact Details";
export const SUBURB = "Suburb";
export const STREET = "Street";
export const PARCEL_HISTORY = "Delivery History";
export const INSTRUCTION_LOCATE_PARCEL =
  "Add any instruction that may help the driver";
export const GO_TO_SEARCH = "Go to Search";
export const BACK = "Back";
export const SEARCH_BY = "Search By";
export const PARCEL_NUMBER = num => `Parcel ${num}`;
export const SEE_ON_MAP = "See on Map";
export const WORKING_HOURS = "Working hours";
export const EXTRACT = "Extract";
export const DEPOT_ADDRESS = "Depot Address";
export const FIND_POSTCODE = "Find Postcode";
export const MAP_VIEW = "Map View";
export const CLOSE = "Close";
export const REPORT_FEEDBACK = "Report feedback";
export const SHOW_DELIVERY_OPTIONS = "Show Delivery Options";
export const WATCH_LIST_ADD_BTN = "Yes - Add To Watch List";
export const WATCH_LIST_REMOVE_BTN = "Yes - Remove From Watch List";
export const COLLECTION_DATE = "Collection Date";
export const COLLECTION_END_DATE = "Collection End Date";
export const COLLECTION_START_DATE = "Collection Start Date";
export const DELIVERY_DATE = "Delivery Date";
export const DESTINATION_TYPE_CODE = "Destination";
export const SHIPPED_DATE = "Shipped Date";
export const TARGET_DELIVERY_DATE = "Target Delivery Date";

// Messages
export const ACTION_CAN_NOT_BE_COMPLETED =
  "We can’t complete this action right now, please try again";
export const SERVICE_IS_NOT_AVAILABLE =
  "Apologies, the service you have chosen is not available for postcode";
export const WATCH_LIST_ADD_TEXT =
  "Please confirm that you’d like to add this to the watch list?";
export const WATCH_LIST_REMOVE_TEXT =
  "Please confirm that you’d like to remove this from the watch list?";
export const CONFIRM_PICKUP_SHOP = "Confirm pickup shop";
export const NOT_FOUND_DELIVERIES =
  "No deliveries were found using that search criteria";
export const FORBIDDEN_ACCESS_TO_DASHBOARD =
  "The dashboard is not enabled for this user";
export const PARCEL_READY_FOR_COLLECTION =
  "This parcel is ready for collection";
export const PARCEL_ISSUE_SUBTEXT =
  "*This date is dependent on conditions improving and may change. Please check here for regular updates.";
export const FURTHER_INTRUCTIONS =
  "Further Instructions are required for this parcel";
export const DISPOSE_REQUEST =
  "Do you confirm for DPD to dispose of all parcels within this consignment?";
export const PLEASE_DELIVER_PARCEL_ON_DATE =
  "Please deliver the parcel on the following date: ";
export const PARCEL_IS_READY_TO_COLLECT_FROM_DEPOT =
  "Parcel is ready for your customer to collect from their depot:";
export const PARCEL_IS_READY_TO_COLLECT_FROM_PICKUP =
  "Parcel is ready for your customer to collect from Pickup Shop";
export const PARCEL_WILL_BE_RETURNED_IF_NOT_COLLECTED =
  "This parcel will be returned to you if it is not collected by:";
export const PARCEL_TO_BE_COLLECTED_FROM_PICKUP_SHOP =
  "Parcel to be collected by your customer from their Pickup Shop";
export const PARCEL_WILL_BE_READY_FOR_COLLECTION =
  "This parcel will be ready for collection:";
export const TO_COLLECT_PARCEL_YOU_NEED_TO_BRING =
  "To collect this parcel, your customer will need to bring";
export const COLLECTION_PARCEL_NOTICE_BOTH =
  "Your customer will need to bring both Utility Bill and Photo ID. Without both of these items we cannot give them their parcel.";
export const NO_NEIGHBOURS = "No neighbours found";
export const COLLECTION_PARCEL_NOTICE_PICKUP_PASS =
  "Your customer will need to bring their Pickup Pass with them. Without it we cannot give them their parcel.";
export const COLLECTION_PARCEL_NOTICE_PICKUP_PASS_BOTH =
  "Your customer will need to bring both Pickup Pass and Photo ID. Without both of these items we cannot give them their parcel.";
export const FAILED_COPY_ADDRESS = "Failed to copy address";
export const COPY_ADDRESS = "Copy Address";
export const COPY_DEPOT_ADDRESS = "Copy Depot Address";
export const ADDRESS_COPIED = "Address copied to clipboard";
export const LATE = "LATE";
export const SAT = "SAT";
export const SUN = "SUN";
export const PROVIDE_NAME = "Please provide Name";
export const PROVIDE_STREET = "Please provide Street";
export const PROVIDE_CITY = "Please provide City";
export const SELECT_RETURNED_ADDRESS = "Return address must be selected";
export const PROVIDE_POSTCODE = "Please provide Postcode";
export const PROVIDE_COUNTRY = "Please provide Country";
export const ACCEPT_TERMS = "Please accept terms";
export const PLEASE_PROVIDE_INSTRUCTIONS =
  "Please provide delivery instructions";
export const ACCEPT_CONDITIONS = "Please accept the terms and conditions";
export const SELECT_OTHER_AND = "Additional instructions must be provided";
export const PROVIDE_CONTACT_NAME = "Please provide Contact Name";
export const INVALID_PICKUP_LOCATION_CODE =
  "Please enter a valid Pickup Location Code";
export const INVALID_PICKUP_DATE = "Please enter a valid Pickup Date";
export const PROVIDE_PHONE_EMAIL =
  "Please provide Notification Mobile or Email";
export const SELECT_TIME = "Select Time";
export const RETURN_ADDRESS = "Return address";
export const TICK_CHECKBOX = "Please tick check box to confirm";
export const COMPLETE_ALL_FIELDS = "Please complete all required fields";
export const SAFE_PLACE_MUST_BE_SELECTED = "Safe Place must be selected";
export const ENTER_VALID_POSTCODE = "Please enter a valid postcode";
export const ENTER_VALID_PHONE = "Please enter a valid Phone Number";
export const ENTER_VALID_EMAIL = "Please enter a valid Email Address";
export const PLEASE_SELECT_PICKUP_SHOP = "Please select pickup shop";
export const INVALID_POSTCODE = "Invalid Postcode";
export const DELIVERY_DATE_MUST_BE_SELECTED = "Delivery Date must be selected";
export const DELIVERY_TIME_MUST_BE_SELECTED = "Delivery Time must be selected";
export const DELIVERY_SERVICE_MUST_BE_SELECTED =
  "Delivery Service must be selected";
export const NEIGHBOUR_MUST_BE_SELECTED = "Neighbour must be selected";
export const MAX_$_CHARACTERS = "Max {0} characters";
export const MAX_15_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 15);
export const MAX_25_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 25);
export const MAX_100_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 100);
export const MAX_15_CHARACTERS_INCLUDING_SPACES = `${MAX_15_CHARACTERS} including spaces`;
export const MAX_35_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 35);
export const MAX_11_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 11);
export const MAX_200_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 200);
export const MAX_1000_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 1000);
export const MAX_50_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 50);
export const MAX_255_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 255);
export const ALL = "all";
export const OR = "or";
export const DASH_DASH = "--";
export const DASH = "-";
export const ACCEPT_COST = "I accept an additional cost may be applied.";
export const ADDITIONAL_INFORMATION = "Additional Information";
export const WE_CANT_FIND =
  "Oops, we can't find what you are looking for. Please try again.";
export const PLEASE_CONFIRM = "Please confirm that the following is accepted:";
export const CHOOSE_PLACE = [
  "Parcel is NOT left in an area with shared access",
  "Parcel is NOT left under any parked vehicle",
  "Receiver accepts full responsibility for parcels that are left in a safe place",
];
export const PLEASE_SELECT_NEIGHBOUR = "Please select a neighbour";
export const CORRECT_HIGHLIGHTED_FIELDS =
  "Please correct the fields highlighted in red";
export const NOT_SURE_WHAT_TO_DO_NEXT =
  "Not sure what to do next with this parcel? We’ll contact you within 4 hours (between 8am - 6:30pm Monday to Friday), but it’s normally less - just provide your details here.";

// Other
export const TO_RECIVE_NOTIFICATIONS = (
  <p className="text-right m-0">
    {"** To Receive Notifications"}
    <br />
    {"Please Provide At Least"}
    <br />
    {"One Email Or Mobile"}
  </p>
);
export const PARCEL_REFERENCE_NOTICE =
  "Parcel, consignment or calling card number";
export const TEXT_CSV_FILE =
  "Please click the Confirm button to download your CSV file";
export const APPLY_THIS_INSTRUCTION_TO_THIS_PARCEL =
  "Apply this instruction to this parcel and these related items:";
export const COLLECT_PARCEL_NOTICE =
  "Ensure two pieces of ID, including a photo ID and a utility bill are presented when collecting the parcel";
export const ADD_INSTRUCTION = "Add any instruction that may help the driver";
export const I_ACCEPT_AN_ADDITIONAL_CHARGE_MAY_BE_APPLIED =
  "I accept an additional charge may be applied";
export const ONLY_DELIVER =
  "Please note we only deliver to PO Box addresses in the following countries - Jordan, Kenya, Oman, Panama, Qatar and Saudi Arabia";
export const CONFIRM_DISPOSED = "I confirm this parcel can be disposed of";
export const ACTION_WILL_APPLY =
  "This action will apply to all parcels in the consignment";
export const SUBMIT = "Submit";
export const SAFE_PLACE = "Safe Place";
export const WARNING = "Warning";
export const OK = "Ok";
export const NO_NEIGHBOURS_FOR_POSTCODE =
  "There have been no neighbours found for your postcode.";
export const NO_PICKUP_SHOPS_FOR_POSTCODE =
  "There have been no Pickup shops found for your postcode";

export const OVERDUE_PATH = "/dashboard/overdue";
export const EXCEPTION_PATH = "/dashboard/rfiException";

export const SUBMIT_PROMPT_REJECT = "submit prompt rejected";
export const NO_PICKUP_SHOPS = "No Pickup Shop Found";
export const ENTER_POSTCODE =
  "Please enter your postcode to check if parcel delivery services in your area have been affected.";
export const EXPORT_STARTED_PLEASE_USE =
  "Export Started, Please Use The Exports Tab To See Progress And Download Your CSV File.";
export const EXPORT_IS_ALREADY_IN_PROGRESS_PLEASE_USE =
  "Export Is Already In Progress. Please Use The Exports Tab To See Progress And Download Your CSV File.";

// Snackbars
export const FAILED_TO_FETCH = "Failed to fetch";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const FAILED_TO_FETCH_ERROR_MESSAGE_$ = `${FAILED_TO_FETCH} {0}`;

export const NO_ACTIONS_AVAILABLE = "No actions available";

export const NO_ACCESS =
  "Sorry, we have not provided you with access to this part of the website.\nPlease contact customer services to enquire about having this added to your account.";
export const NO_WATCH_LIST_ITEMS =
  "Sorry, but it looks like you have no parcels in your watch list";

export const COLLECTION_DATE_MUST_BE_SELECTED =
  "Collection Date must be selected";
export const COLLECTION_TIME_MUST_BE_SELECTED =
  "Collection Time must be selected";

export const EMAIL_IMAGE = "Email Image";
export const IMAGE_VIEW = "Image View";
export const PLEASE_COMPLETE_FIELDS =
  "Please complete the fields below to have a copy of the selected images emailed to you";

export const SERVICE_DISRUPTION_MESSAGE =
  "This area is currently inaccessible to our drivers. As soon as conditions improve, and it is safe to do so, our drivers will attempt delivery.";
