import { createSelector } from "reselect";

export const getData = state => state.exporterParcels;

export const getFindByCode = createSelector(
  getData,
  ({ findByCode }) => findByCode
);

export const getTotalExporterParcelsCount = createSelector(
  getData,
  ({ totalExporterParcels }) => totalExporterParcels
);

export const getTotalExporterParcels = createSelector(
  getData,
  ({ exporterParcels }) => exporterParcels
);
