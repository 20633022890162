import { getApiOrigin } from "../utils/config";
import apisClient from "./api";
import * as locationApi from "./location";
import * as messageApi from "./message";
import * as deliveriesApi from "./parcels";
import * as pickupsApi from "./pickup";
import * as exporterParcelsApi from "./exporterParcels";

const APIS_ORIGIN_URL = getApiOrigin();
export {
  APIS_ORIGIN_URL,
  apisClient,
  locationApi,
  deliveriesApi,
  pickupsApi,
  messageApi,
  exporterParcelsApi,
};
