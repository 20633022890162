import { useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { useFlexLayout, useRowSelect } from "react-table";
import { compose } from "redux";
import { reduxForm } from "redux-form";

import { APP_ROLES } from "@dpdgroupuk/mydpd-enums";
import {
  DndTable as Table,
  DndPaginator as TablePaginator,
} from "@dpdgroupuk/mydpd-ui";

import CardWithTitle from "../../../components/CardWithTitle";
import { WATCH_LIST_FORM } from "../../../constants/forms";
import { RESULTS } from "../../../constants/strings";
import { DeliveryListColumns } from "../../../features/DeliveryListView";
import withAppRolesValidator from "../../../HOCS/withAppRolesValidator";
import Footer from "../Footer";
import styles from "../WatchListView.module.scss";

const WatchList = ({
  parcels,
  page,
  totalCount,
  pageSize,
  onPrevious,
  onNext,
  onFirst,
  onLast,
  onClickRow,
  setSelectedItems,
  selectedItems,
  onRemoveClick,
  onExportPress,
  findByCode,
  hasAppRoles,
  deliveryType,
}) => {
  const canEdit = hasAppRoles(APP_ROLES.CHANGE_DELIVERY);
  const tableRef = useRef();

  const footer = useMemo(
    () => (
      <Footer
        isItemSelected={!!selectedItems.length}
        onRemoveClick={() =>
          onRemoveClick(selectedItems.map(({ parcelCode }) => parcelCode))
        }
        onExportPress={onExportPress}
        canEdit={canEdit}
      />
    ),
    // eslint-disable-next-line
    [selectedItems, onRemoveClick, findByCode, onExportPress]
  );
  const memoColumns = useMemo(
    () => DeliveryListColumns(deliveryType),
    [deliveryType]
  );

  return (
    <CardWithTitle title={RESULTS}>
      <div className={styles.table}>
        <Table
          tableRef={tableRef}
          columns={memoColumns}
          data={parcels}
          onDoubleClickRow={onClickRow}
          getSelectedRows={setSelectedItems}
          tableHooks={[useRowSelect, useFlexLayout]}
          selectable={canEdit}
        >
          <TablePaginator
            tableRef={tableRef}
            page={page}
            pageSize={pageSize}
            totalCount={totalCount}
            onNext={onNext}
            onPrevious={onPrevious}
            onFirst={onFirst}
            onLast={onLast}
          />
          {footer}
        </Table>
      </div>
    </CardWithTitle>
  );
};

WatchList.defaultProps = {
  deliveryType: "",
};

export const propTypes = {
  parcels: PropTypes.arrayOf(PropTypes.object),
  onClickRow: PropTypes.func,
  hasAppRoles: PropTypes.func,
  deliveryType: PropTypes.string,
  // eslint-disable-next-line react/forbid-foreign-prop-types
  ...TablePaginator.propTypes,
};

WatchList.propTypes = propTypes;

export default compose(
  reduxForm({
    form: WATCH_LIST_FORM,
  }),
  withAppRolesValidator
)(WatchList);
