import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

import { Form } from "@dpdgroupuk/mydpd-ui";

import Title from "../../../../../components/Title";
import { Fields } from "../../../../../constants/forms";
import {
  ADD_INSTRUCTION,
  INSTRUCTION_AND_CONTACT_DETAILS,
  MAX_200_CHARACTERS,
} from "../../../../../constants/strings";
import ContactDetailsFields from "../ContactDetails";
import styles from "./ContactDetailsWithInstructions.module.scss";

const NotificationDetails = ({
  showNotificationTitle,
  isMobileEmailRequired,
  offContactDetailsValidation,
  onInstructionsChange,
}) => (
  <>
    {showNotificationTitle && (
      <Col className="col-12">
        <Title
          title={INSTRUCTION_AND_CONTACT_DETAILS}
          containerStyle={styles.title}
        />
      </Col>
    )}
    <ContactDetailsFields
      isMobileEmailRequired={isMobileEmailRequired}
      offValidation={offContactDetailsValidation}
    />
    <Col className="col-12">
      <Field
        component={Form.MaterialTextInput}
        multiline
        classes={{
          input: styles.textarea,
        }}
        rows={8}
        label={ADD_INSTRUCTION}
        name={Fields.INSTRUCTIONS}
        maxLength={200}
        helperText={MAX_200_CHARACTERS}
        onChange={onInstructionsChange}
      />
    </Col>
  </>
);

NotificationDetails.propTypes = {
  showNotificationTitle: PropTypes.bool,
  isMobileEmailRequired: PropTypes.bool,
  offContactDetailsValidation: PropTypes.bool,
  onInstructionsChange: PropTypes.func,
};

export default NotificationDetails;
