import api from "./api";

export const getExporterParcel = (exporterParcelId, options) =>
  api.get({
    path: `/exporter/parcel/${exporterParcelId}`,
    options,
  });

export const getExporterParcels = (query, options) =>
  api.get({
    path: "/exporter/parcel",
    query,
    options,
  });

export const createExporterParcel = (query, options) =>
  api.post({
    path: "/exporter/parcel",
    query,
    options,
  });

export const getExporterParcelsUrl = (query, options) =>
  api.post({
    path: "/exporter/parcel/generateFileUrl",
    query,
    options,
  });
