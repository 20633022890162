import omit from "lodash/omit";
import { change, getFormValues, initialize, untouch } from "redux-form";

import { PARCEL_ACTION_CODE } from "@dpdgroupuk/redback-enums";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { deliveriesApi, locationApi } from "../../apis";
import {
  alternativeAddressUpgrade as Fields,
  PARCEL_EDIT_FORM,
} from "../../constants/forms";
import {
  getActionDates,
  getAlternativeDateRange,
  getParcelFormValues,
  getReturnAddresses,
} from "./selectors";
import moment from "moment/moment";
import {
  ALTERNATIVE_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "../../constants/dateFormats";
import { getValue, toUppercaseValues } from "../../utils/object";
import { includes } from "lodash";
import {
  formInitialValues,
  getRelatedParcelsInitialValues,
} from "./view/ReturnToSender/model";
import { getRelatedParcels } from "../Parcel/selectors";

export const ActionTypes = createActionTypes("EDIT_PARCEL", {
  GET_NEIGHBOUR_ADDRESSES: createAsyncActionTypes("GET_NEIGHBOUR_ADDRESSES"),
  GET_RETURN_ADDRESS: createAsyncActionTypes("GET_RETURN_ADDRESS"),
  GET_ACTION_DATES: createAsyncActionTypes("GET_ACTION_DATES"),
  GET_PICKUP_LOCATION_SHOP: createAsyncActionTypes("GET_PICKUP_LOCATION_SHOP"),
  GET_DEPOT: createAsyncActionTypes("GET_DEPOT"),
  GET_UPGRADE_DELIVERY_TIMES: createAsyncActionTypes(
    "GET_UPGRADE_DELIVERY_TIMES"
  ),
  GET_ALTERNATIVE_DELIVERY_DATES: createAsyncActionTypes(
    "GET_ALTERNATIVE_DELIVERY_DATES"
  ),
  GET_ALTERNATIVE_DELIVERY_TIMES: createAsyncActionTypes(
    "GET_ALTERNATIVE_DELIVERY_TIMES"
  ),
  CLEAR_ALTERNATIVE_DATES: "CLEAR_ALTERNATIVE_DATES",
  CLEAR_DELIVERY_EDIT_DATA: "CLEAR_DELIVERY_EDIT_DATA",
  SELECT_PICKUP_SHOP: "SELECT_PICKUP_SHOP",
  CLEAR_SERVICES: "CLEAR_SERVICES",
  CLEAR_DATE_AND_SERVICE: "CLEAR_DATE_AND_SERVICE",
  CAN_SUBMIT: createAsyncActionTypes("CAN_SUBMIT"),
  SFP_SUBMIT: createAsyncActionTypes("SFP_SUBMIT"),
  CFN_SUBMIT: createAsyncActionTypes("CFN_SUBMIT"),
  CHD_SUBMIT: createAsyncActionTypes("CHD_SUBMIT"),
  INS_SUBMIT: createAsyncActionTypes("INS_SUBMIT"),
  RBK_SUBMIT: createAsyncActionTypes("RBK_SUBMIT"),
  TBC_SUBMIT: createAsyncActionTypes("TBC_SUBMIT"),
  DTN_SUBMIT: createAsyncActionTypes("DTN_SUBMIT"),
  KMI_SUBMIT: createAsyncActionTypes("KMI_SUBMIT"),
  RTC_SUBMIT: createAsyncActionTypes("RTC_SUBMIT"),
  ALT_SUBMIT: createAsyncActionTypes("ALT_SUBMIT"),
  UPC_SUBMIT: createAsyncActionTypes("UPC_SUBMIT"),
  LOC_SUBMIT: createAsyncActionTypes("LOC_SUBMIT"),
  CME_SUBMIT: createAsyncActionTypes("CME_SUBMIT"),
  ALU_SUBMIT: createAsyncActionTypes("ALU_SUBMIT"),
  PKU_SUBMIT: createAsyncActionTypes("PKU_SUBMIT"),
});

const createSubmitAsyncAction = (apiMethod, actionType) =>
  createAsyncAction(
    (parcelCode, values) =>
      apiMethod(parcelCode, values).then(({ data }) => data.parcelCode),
    actionType
  );

export const fetchActionDates = createAsyncAction(
  deliveriesApi.getDeliveryActionDates,
  ActionTypes.GET_ACTION_DATES
);

export const fetchUpgradeDeliveryTimes = createAsyncAction(
  deliveriesApi.getUpgradeDeliveryTimes,
  ActionTypes.GET_UPGRADE_DELIVERY_TIMES
);

export const fetchDepot = createAsyncAction(
  locationApi.getDepot,
  ActionTypes.GET_DEPOT
);

export const fetchAlternativeDeliveryDates = createAsyncAction(
  deliveriesApi.getAlternativeDeliveryDates,
  ActionTypes.GET_ALTERNATIVE_DELIVERY_DATES
);

export const fetchAlternativeDeliveryTimes = createAsyncAction(
  deliveriesApi.getAlternativeDeliveryTimes,
  ActionTypes.GET_ALTERNATIVE_DELIVERY_TIMES
);

export const clearAlternativeDeliveryDates = () => ({
  type: ActionTypes.CLEAR_ALTERNATIVE_DATES,
});

export const clearDeliveryEditData = () => ({
  type: ActionTypes.CLEAR_DELIVERY_EDIT_DATA,
});

export const clearDateAndService = () => ({
  type: ActionTypes.CLEAR_DATE_AND_SERVICE,
});

export const clearServices = () => ({
  type: ActionTypes.CLEAR_SERVICES,
});

export const onSelectPickupShop = shop => ({
  type: ActionTypes.SELECT_PICKUP_SHOP,
  payload: shop,
});

export const resetAddressAction = () => (dispatch, getState) => {
  const currentValues = getFormValues(PARCEL_EDIT_FORM)(getState());
  const values = omit(currentValues, [
    "postcode",
    "name",
    "street",
    "suburb",
    "town",
    "city",
    "county",
  ]);
  return dispatch(initialize(PARCEL_EDIT_FORM, values));
};

export const getAlternativeDatesAction =
  (address, actionCode, parcel) => (dispatch, getState) => {
    const currentValues = getFormValues(PARCEL_EDIT_FORM)(getState());
    const values = {
      ...currentValues,
      postcode: address.postcode,
      name: address.organisation,
      street: address.street,
      suburb: address.property,
      town: address.locality,
      city: address.town,
      county: address.county,
    };
    dispatch(initialize(PARCEL_EDIT_FORM, values));
    const query = {
      existingDeliveryAddress: {
        countryCode: parcel.deliveryDetails.address.countryCode,
        postcode: parcel.deliveryDetails.address.postcode,
      },
      newDeliveryAddress: {
        countryCode: values.countryCode,
        postcode: values.postcode,
      },
      changeService: actionCode !== PARCEL_ACTION_CODE.ALT,
      targetDate: parcel.customerTargetDate,
    };
    return dispatch(
      fetchAlternativeDeliveryDates(parcel.parcelCode, actionCode, query)
    );
  };

export const getAlternativeTimesAction =
  (actionCode, parcel, date) => async (dispatch, getState) => {
    const values = getFormValues(PARCEL_EDIT_FORM)(getState());
    const query = {
      date,
      newDeliveryAddress: {
        countryCode: values.countryCode,
        postcode: values.postcode,
        city: values.city,
        county: values.county,
        locality: values.locality,
        street: values.street,
        town: values.town,
      },
    };
    return dispatch(
      fetchAlternativeDeliveryTimes(parcel.parcelCode, actionCode, query)
    );
  };

export const fetchNeighbourAddresses = createAsyncAction(
  ({ postcode }, options) =>
    locationApi.getAddresses(
      {
        postcode,
      },
      options
    ),
  ActionTypes.GET_NEIGHBOUR_ADDRESSES
);

export const onChangeParcelDateSubmit = createSubmitAsyncAction(
  deliveriesApi.changeDeliveryDate,
  ActionTypes.CHD_SUBMIT
);

export const onChangeParcelDateSubmitREI = createSubmitAsyncAction(
  deliveriesApi.changeDeliveryDateREI,
  ActionTypes.CHD_SUBMIT
);

export const onDeliverToSafePlaceSubmit = createSubmitAsyncAction(
  deliveriesApi.changeSafePlace,
  ActionTypes.SFP_SUBMIT
);

export const onDeliverToNeighbourSubmit = createSubmitAsyncAction(
  deliveriesApi.deliverToNeighbour,
  ActionTypes.DTN_SUBMIT
);

export const onCollectFromDepot = createSubmitAsyncAction(
  deliveriesApi.collectFromDepot,
  ActionTypes.TBC_SUBMIT
);

export const onUpdateContactDetailsSubmit = createSubmitAsyncAction(
  deliveriesApi.updateContactDetails,
  ActionTypes.KMI_SUBMIT
);

export const onProvideDeliveryInstructions = createSubmitAsyncAction(
  deliveriesApi.provideDeliveryInstructions,
  ActionTypes.LOC_SUBMIT
);

export const fetchReturnAddress = createAsyncAction(
  deliveriesApi.getReturnAddress,
  ActionTypes.GET_RETURN_ADDRESS
);
export const fetchPickupLocationShop = createAsyncAction(
  deliveriesApi.getPickupLocationShop,
  ActionTypes.GET_PICKUP_LOCATION_SHOP
);

export const onReturnToConsignor = createSubmitAsyncAction(
  deliveriesApi.returnToConsignor,
  ActionTypes.RTC_SUBMIT
);

export const onAlternativeAddress = createSubmitAsyncAction(
  deliveriesApi.updateAddress,
  ActionTypes.ALT_SUBMIT
);

export const onAdjustService = createSubmitAsyncAction(
  deliveriesApi.adjustService,
  ActionTypes.UPC_SUBMIT
);

export const onAlternativeAddressService = createSubmitAsyncAction(
  deliveriesApi.updateAddressAndService,
  ActionTypes.ALU_SUBMIT
);

export const onCallMe = createSubmitAsyncAction(
  deliveriesApi.callMe,
  ActionTypes.CME_SUBMIT
);
export const onCollectFromPickupShop = createSubmitAsyncAction(
  deliveriesApi.onCollectFromPickupShop,
  ActionTypes.PKU_SUBMIT
);

export const clearDeliveryService = () => dispatch => {
  dispatch(change(PARCEL_EDIT_FORM, Fields.DELIVERY_SERVICE, ""));
  dispatch(untouch(PARCEL_EDIT_FORM, Fields.DELIVERY_SERVICE));
};

export const getDatesRange = () => (_, getState) =>
  getAlternativeDateRange(getState());

export const isAvailableDate = selectedDate => (dispatch, getState) => {
  const dates = getActionDates(getState());

  return includes(dates, moment(selectedDate).format(ALTERNATIVE_DATE_FORMAT));
};

export const checkMinDate = () => (dispatch, getState) => {
  const state = getState();
  const formValues = getParcelFormValues(state);
  const date = formValues[Fields.DELIVERY_DATE];
  const currentDate = moment();

  if (moment(date, DEFAULT_DATE_FORMAT).isBefore(currentDate, "day")) {
    dispatch(
      change(
        PARCEL_EDIT_FORM,
        Fields.DELIVERY_DATE,
        currentDate.format(DEFAULT_DATE_FORMAT)
      )
    );
  }
};

export const onAddressChange =
  (value, defaultNotificationDetails) => (dispatch, getState) => {
    const state = getState();
    const formValues = getParcelFormValues(state);
    const addresses = getReturnAddresses(state);
    const relatedParcelsInitialValues = getRelatedParcelsInitialValues(
      getRelatedParcels(state)
    );
    const data = toUppercaseValues(
      getValue(addresses, [value], {
        ...formInitialValues,
        ...defaultNotificationDetails,
      })
    );

    return dispatch(
      initialize(PARCEL_EDIT_FORM, {
        ...formValues,
        ...data,
        ...relatedParcelsInitialValues,
      })
    );
  };
